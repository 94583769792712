let _editorSDK;
let _token;

const APP_DEF_ID = '1484cb3f-5083-75ec-9681-75188ab429de'; //TODO: change to the appDefId of of your app from dev center
const WIDGET_DEV_CENTER_ID = '470924e6-5773-412b-9346-c7a51e426935'; //TODO: change to the widget id (guid) of of your widget from dev center
const WIDGET_APP_BUILDER_ID = 'vy80o'; //TODO: change to the id of the page of your widget from app builder
const CONTROLLER_TYPE = APP_DEF_ID + '-' + WIDGET_APP_BUILDER_ID;

export const editorReady = async (editorSDK, token, { firstInstall }) => {
  _editorSDK = editorSDK;
  _token = token;

  if (firstInstall || !(await controllerAlreadyExists(CONTROLLER_TYPE))) {
    console.log('Creating missing widget by ref!');

    const pageRef = await editorSDK.pages.getCurrent(token);
    const componentDefinition = {
      componentType: 'wysiwyg.viewer.components.RefComponent',
      data: {
        type: 'WidgetRef',
        appDefinitionId: APP_DEF_ID,
        widgetId: WIDGET_DEV_CENTER_ID,
      },
    };
    console.log('Creating missing widget by ref@');

    await editorSDK.document.components.add('token', {
      componentDefinition,
      pageRef: pageRef,
    });
    console.log('Creating missing widget by ref#');
  }
};

export const onEvent = async ({ eventType, eventPayload }) => {
  if (!eventPayload) {
    return;
  }

  const { controllerRef } = eventPayload;
  if (eventType === 'controllerGfppClicked') {
    if (eventPayload.id === 'sendTextToConsole') {
      const data = await _editorSDK.components.data.get(_token, {
        componentRef: eventPayload.componentRef,
      });
      console.log('This is my demo gfpp printing comp data:', data.text);
      return;
    }

    const options = {
      title: 'Manage My First Application',
      url: './settings.html',
      initialData: {
        controllerRef,
      },
      width: '80%',
      height: '80%',
    };
    await _editorSDK.editor.openComponentPanel(_token, {
      ...options,
      componentRef: controllerRef,
    });
  } else if (eventType === 'widgetGfppClicked') {
    if (eventPayload.id === 'openSettingClicked')
      console.log(
        'here the app will open some panel using the lovely editor sdk - check the docs: https://bo.wix.com/wix-docs/client/client-frameworks#editor-platform.articles.application-panels'
      );
    return;
  }
};

export const getAppManifest = () => {
  return {
    controllersStageData: {
      [CONTROLLER_TYPE]: {
        default: {
          gfpp: {
            desktop: {
              mainAction1: {
                label: 'App Set!',
                actionId: 'openSettingClicked',
              },
            },
          },
          connections: {
            title: {
              //componentRole
              gfpp: {
                desktop: {
                  mainAction2: {
                    label: 'Send Text To Console',
                    actionId: 'sendTextToConsole',
                  },
                },
              },
            },
          },
        },
      },
    },
  };
};

async function controllerAlreadyExists(controllerType) {
  const controllers = await _editorSDK.controllers.listAllControllers(_token);
  for (const controller of controllers) {
    const data = await _editorSDK.controllers.getData(_token, controller);
    if (data.controllerType === controllerType) {
      return true;
    }
  }
  return false;
}
